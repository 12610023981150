<template>
<v-app>
  <v-navigation-drawer
      
      v-model="drawer"
      fixed
      temporary
    >
        <v-subheader class="mt-3 grey--text text--darken-1">Menu</v-subheader>
        <v-card
            tile
            flat
          >
            <v-list-item v-for="item in items2" :key="item.text" :href="item.id">
              <v-list-item-content>
                <v-list-item-title>{{item.text}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
    </v-navigation-drawer>
    <v-app-bar
      fixed
      
      color="deep-purple darken-1"
      dark
    >
      <!--<v-toolbar-title>Page title</v-toolbar-title>-->
      <a v-bind:href="'https://jogjaleker.co.id/'">
          <img :src="img_url + '1MQXgaUOAeJDrUDOinvR3JoRzTv8BUhfQ&sz=w170-rw'" width="120px">
      </a>
      
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-container>
    <v-layout row wrap class="mt-12">
       <v-flex xs12 id="beranda"><v-img :src="'https://drive.google.com/thumbnail?id=1A-iY_96qfilklO-aw10WeWN8HP5MRZKw&sz=w' + width  + '-rw'" alt="Responsive image"></v-img></v-flex>
       
        <v-flex xs12 id="" class="ma-1">
              <v-card class="subheading text-left pa-1">  
                     <ul>
                       <li>Mau punya penghasilan tambahan tapi bingung usaha apa dan memulai dari mana ?</li>
                       <li>Mau punya bisnis makanan, tapi tidak mau ribet mikir bahan bakunya ?</li>
                       <li>Sudah coba banyak <b>Resep</b> namun gagal membuatnya ?</li>
                     </ul>  
              </v-card>
        </v-flex>
        <v-flex xs12 id="">
         <v-card-title class="deep-purple darken-2">
          <span class="headline white--text">KAMI PUNYA SOLUSINYA</span>

          <v-spacer></v-spacer>
         </v-card-title>
        </v-flex>
        <v-flex xs12 id=""><v-img :src="'https://drive.google.com/thumbnail?id=17_IV-wCBfuxh_brQwOj64CM05lLMScpZ&sz=w' + width  + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs-12 id="" class="ma-1">
                <v-card
                  class="mx-auto"
                  color="#5e35b1"
                  dark
                  max-width="100%"
                >
                  <v-card-text class="headline font-weight-bold text-center color='white'">
                    Ribuan orang sudah berhasil mendapatkan penghasilan tambahanan melalui bisnis <span class="font-weight-black">JOGJA LEKER</span><br>
                  </v-card-text>

                  <v-card-actions>
                    <v-list-item class="grow">
                      <v-list-item-avatar color="white">
                        <v-img
                          class="elevation-6"
                          alt=""
                          :src="img_url + '1MQXgaUOAeJDrUDOinvR3JoRzTv8BUhfQ&sz=w170-rw'" width="120px">
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>Jogja Leker</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
        </v-flex>
        <v-flex xs12 id="testi" class="text-center">
          <v-card-title class="deep-purple darken-2">
              <span class="headline white--text">KATA MITRA JOGJA LEKER ?</span>
          </v-card-title>
          <v-flex xs12 id="">
            <v-row cols="12">
                      <v-col cols="6" class="text-center">
                               <img :src="img_url + '1VQH7dX-X5QKhG-SnABN1nigY5dCaAWCw&sz=w0-rw'" width="100%">
                      </v-col>
                      <v-col cols="6" class="text-center">
                                <img :src="img_url + '1hKmMDvEFcrNq8As5R_IXK6hFaaFgG0_o&sz=w0-rw'" width="100%">
                      </v-col>
                      
            </v-row>
            <v-row cols="12">
                      <v-col cols="6" class="text-center">
                                <img :src="img_url + '1rekET19BnfXVMmwArDrvc2hjri5EBOmP&sz=w0-rw'" width="100%">
                      </v-col>
                      <v-col cols="6" class="text-center">
                                <img :src="img_url + '19C3bl1qXjMTnTNhxKkJY967Bw6wx5Ivi&sz=w0-rw'" width="100%">
                      </v-col>
            </v-row>
            <v-row cols="12">
                      <v-col cols="6" class="text-center">
                                <img :src="img_url + '1VRrbkw5a3nZrvO3Ljy6fIM_j6jl_Utvv&sz=w0-rw'" width="100%">
                      </v-col>
                      <v-col cols="6" class="text-center">
                                <img :src="img_url + '1juHc58Chwna-UanG1lghguJdriNSDdGP&sz=w0-rw'" width="100%">
                      </v-col>
            </v-row>
       </v-flex>
        </v-flex>
       <!-- <v-flex xs12 id=""><v-img :src="'https://drive.google.com/thumbnail?id=1CBCZce9pXNxNdUyPzNx5CCQksjoux-O4&sz=w' + width  + '-rw'" alt="Responsive image"></v-img></v-flex> -->
       <v-flex xs12 id="produk">
          <v-card-title class="deep-purple darken-2">
              <span class="headline white--text">VARIAN RASA JOGJA LEKER</span>
          </v-card-title>
          
          <!-- <v-row cols="12" class=" kayu deep-purple lighten-3 white--text ma-1">
                      <v-col cols="4" class="text-center">
                               <img :src="img_url + '1t5jjroyNA5zuKy_ejkRRKUfxHbZEaQwH&sz=w0-rw'" width="100%">
                      </v-col>
                      <v-col cols="4" class="text-center">
                                <img :src="img_url + '1oaMuODu1RCEbZ35xcDfpnYOMQFrRKDHW&sz=w0-rw'" width="100%">
                      </v-col>
                       <v-col cols="4" class="text-center">
                                <img :src="img_url + '1tTp7mpfRCCUXnFDSmTjC9-0j70O5fjHO&sz=w0-rw'" width="100%">
                      </v-col>
                      
            </v-row>
            <v-row cols="12" class="kayu deep-purple lighten-3 white--text ma-1">
                      <v-col cols="4" class="text-center">
                                <img :src="img_url + '1yxjwB8YXyLNNjZVaM80Cxcde8orF_0Vo&sz=w0-rw'" width="100%">
                      </v-col>
                      <v-col cols="4" class="text-center">
                                <img :src="img_url + '1DBfFrFO4GQf-qBg2XJIunXPwv15ybhAu&sz=w0-rw'" width="100%">
                      </v-col>
                       <v-col cols="4" class="text-center">
                                <img :src="img_url + '10F4uOb3p1RFuOsLJVKuV6O4iK6bTCZYy&sz=w0-rw'" width="100%">
                      </v-col>
            </v-row>
        

 -->

          <v-img :src="'https://drive.google.com/thumbnail?id=1eo2XG9TXAxp5d_R6uKFs_jj4XizX9vOO&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
       </v-flex>
        <v-flex xs12 id="faq" class="ml-2 mr-2">
            <template>
              <v-expansion-panels>
                <v-expansion-panel>
                </v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                      <p class="font-weight-black text-center max-width=320">JOGJA LEKER</p>
                      <template v-slot:actions>
                        <v-icon color="primary">
                          mdi-comment-question-outline
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                <v-expansion-panel>
                  <v-expansion-panel-header class="deep-purple darken-2 white--text">
                    APA ITU JOGJA LEKER ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="subtitle-2 text-justify">
                          <p class="text-justify"><b>Leker</b> merupakan makanan tradisional yang lebih dikenal sebagai jajanan masa kecil. Jenis makanan yang sudah jarang ditemui ini diolah menjadi lebih menarik serta mengikuti perkembangan jaman dengan variasi topping yang lebih <i>modern</i> dan beragam serta kemasan yang <i>eye-catching</i>, tetapi tetap mempertahankan unsur tradisional dalam cara memasaknya. Keistimewaan dari <b>Jogja Leker</b> terletak pada perpaduan unsur tradisional dan modern-nya. Pada akhir tahun 2010, JOKER turut berpartisipasi dalam sebuah kompetisi bisnis yang diadakan oleh <b>Himpunan Pengusaha Muda Indonesia (HIPMI)</b>. Di kompetisi ini, JOKER berhasil menyabet JUARA PERTAMA sehingga pasar JOKER pun semakin luas.
JOKER memiliki pangsa pasar yang luas karena dapat dinikmati oleh semua kalangan mulai dari anak-anak hingga dewasa</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
               

              </v-expansion-panels>
            </template>
        </v-flex>        
        <v-flex xs12 id="" class="ma-2">
              <v-card class="subheading text-left pa-2">  
                  <h2 class="text-center">HANYA DENGAN MODAL <span class="font-weight-black">7 JUTAAN</span> ANDA TAK PERLU RIBET MEMIKIRKAN : </h2> <br>
                     <ul>
                       <li>Brandnya apa ?</li>
                       <li>Produknya apa ?</li>
                       <li>Riset produknya bagaimana ?</li>
                       <li>Menyiapkan berbagai alat untuk jualan</li>
                       <li>Promosinya seperti apa?</li>
                       <li>Resepnya seperti apa?</li>
                       <li>Mencari suplier bahan baku di mana ?</li>
                       <li>Menyiapkan sarana promosi</li>
                     </ul>  
                     <br>
                     <p class="text-justify">Tenangggg..... Kami Sudah menyediakan itu semua khusus untuk anda yang sedang mengalami kesulitan <span class="font-weight-black">EKONOMI</span> di masa Pandemi ini.</p>
              </v-card>
        </v-flex>
        <v-flex xs12 id="timeline" class="pa-2">
          <v-card-title class="deep-purple darken-2">
          <span class="headline white--text">KENAPA JOGJA LEKER ?</span>
          <v-spacer></v-spacer>
         </v-card-title>
                  <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                    <v-timeline-item
                      color="purple lighten-2"
                      fill-dot
                      right
                    >
                      <v-card>
                        <v-card-title class="purple lighten-2">
                          <p class="white--text text-justify">
                            HARGA PAKET <br>TERJANGKAU
                          </p>
                        </v-card-title>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              md="10"
                              
                            >
                              <p class="text-justify">JOGJA LEKER memiliki harga jual produk yang terjangkau di semua kalangan masyarakat, Paket awal usaha tersedia dalam berbagai pilihan yang sangat terjangkau.</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-timeline-item>

                    <v-timeline-item
                      color="amber lighten-1"
                      fill-dot
                      left
                      small
                    >
                      <v-card>
                        <v-card-title class="amber lighten-1">
                          <p class="white--text text-justify">
                            100% KEUNTUNGAN <br> MILIK MITRA
                          </p>
                        </v-card-title>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              md="12"
                            >
                              Menjadi mitra Jogja Leker maka keuntungan yang didapat 100% milik anda. Tidak ada sistem bagi hasil dan manajemen Fee.
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-timeline-item>

                    <v-timeline-item
                      color="cyan lighten-1"
                      fill-dot
                      right
                    >
                      <v-card>
                        <v-card-title class="cyan lighten-1">
                          <p class="white--text text-center">
                            OMZET MELIMPAH
                          </p>
                        </v-card-title>
                        <v-container>
                          <v-row>
                            <v-col
                            >
                              Meski modal awal yang sangat kecil, namun omset yang diperoleh sangat besar hingga belasan juta rupiah perbulannya.
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-timeline-item>

                    <v-timeline-item
                      color="red lighten-1"
                      fill-dot
                      left
                      small
                    >
                      <v-card>
                        <v-card-title class="red lighten-1">
                         <p class="white--text text-justify">
                            BUKAN PRODUK <br> MUSIMAN
                        </p>
                        </v-card-title>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              md="12"
                            >
                              JOGJA LEKER diproses dengan mengedepankan kualitas bahan baku, sehingga memiliki cita rasa yang khas. Selain itu, Produk Leker merupakan makanan jadul yang tetap Eksis sampai sekarang.
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-timeline-item>

                    <v-timeline-item
                      color="green lighten-1"
                      fill-dot
                      right
                    >
                      <v-card>
                        <v-card-title class="green lighten-1">
                          <p class="white--text text-justify">
                            SUPPORT <br>DIGITAL MARKETING
                          </p>
                        </v-card-title>
                        <v-container>
                          <v-row>
                            <v-col>
                              JOGJA LEKER didukung dengan media promosi digital yang inovatif sehingga akan sangat mudah dikenal masyarakat dan meningkatkan daya tarik terhadap produk Jogja Leker.
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
        </v-flex>
        <v-flex xs12 id="paket" class="pa-1">
            <template>
                    <v-card>
                      <v-toolbar
                        flat
                        color="#5e35b1"
                        dark
                      >
                        <v-toolbar-title>PAKET KEMITRAAN</v-toolbar-title>
                      </v-toolbar>
                      <v-tabs horizontal>
                        <v-tab>
                          <v-icon left>
                            mdi-store
                          </v-icon>
                          GOLD
                        </v-tab>
                        <v-tab>
                          <v-icon left>
                            mdi-store
                          </v-icon>
                          SILVER
                        </v-tab>
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                               <img :src="img_url + '16qOo1HqOcvYI_kdUsevnst1DxVn9UEii&sz=w0-rw'" width="100%">
                                   <v-btn  href="validasi.php?" block icon-and-text dark color="green darken-4">
                                        <v-icon left>mdi-whatsapp</v-icon>
                                            AMBIL PAKET
                                    </v-btn>
                               <!-- <p>
                                 PAKET
                                 <ul>
                                  <li>Gerobak</li>
                                  <li>Bahan baku</li>
                                  <li>Peralatan</li>
                                  <li>Brosur</li>
                                  <li>Seragam Karyawan</li>
                                  <li>Support iklan</li>
                                 </ul>
                              </p> -->
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <img :src="img_url + '1xuQeYpSb_qPmebyhtX-bFY1X-f0NgUcg&sz=w0-rw'" width="100%">
                                    <v-btn  href="validasi.php?" block icon-and-text dark color="green darken-4">
                                        <v-icon left>mdi-whatsapp</v-icon>
                                            AMBIL PAKET
                                    </v-btn>
                               <!-- <p>
                                 PAKET
                                 <ul>
                                  <li>Gerobak</li>
                                  <li>Bahan baku</li>
                                  <li>Peralatan</li>
                                  <li>Brosur</li>
                                  <li>Seragam Karyawan</li>
                                  <li>Support iklan</li>
                                 </ul>
                              </p> -->
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card>
                  </template>
        </v-flex>
        <v-flex xs12 id="benefit">

                <v-expansion-panel-header disable-icon-rotate>
                
                      <p class="font-weight-black text-center">ANDA KHAWATIR PAKET TIDAK SAMPAI LOKASI ? <br>
                      TENANG... KAMI PUNYA SOLUSINYA</p>
                      <template v-slot:actions>
                        <v-icon color="primary">
                          mdi-comment-question-outline
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
          
                  <v-row cols="12">

                      <v-col cols="4" class="text-center">
                               <img :src="img_url + '15FHoPZFUM5yTwbuXeFg-8sBzROnAot15&sz=w0-rw'" width="100%">
                               <p class="font-weight-bold text-center">
                                 COD
                                </p>
                      </v-col>
                      <v-col cols="4" class="text-center">
                                <img :src="img_url + '1WTC3_e1Xk860FUVBtjIcjBjozvQNvBBY&sz=w0-rw'" width="100%">
                               <p class="font-weight-bold text-center">
                                 SHOPEE
                                </p>
                      </v-col>
                      <v-col cols="4" class="text-center">
                                <img :src="img_url + '1XYpGwyDeeY4aVMnN1f9OSgZ08wAgGEOy&sz=w0-rw'" width="100%">
                               <p class="font-weight-bold text-center">
                                 TOKOPEDIA
                                </p>
                      </v-col>
                  </v-row>
        </v-flex>
        <v-flex xs12 id="">
          <v-img :src="'https://drive.google.com/thumbnail?id=1_AMf2HQ5tnNlIACbgTK9r67Aw7lkCT8e&sz=w' + width  + '-rw'" alt="Responsive image"></v-img>
        </v-flex>
        <!-- <v-flex xs12 id="roi"><v-img :src="'https://drive.google.com/thumbnail?id=1BgwNp_z5LM0dj3m9YjfhRm_7bgjFHqGr&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex> -->
        <v-flex xs12 id="roi"><img :src="img_url + '1BgwNp_z5LM0dj3m9YjfhRm_7bgjFHqGr&sz=w0-rw'" width="100%"></v-flex>
        <!-- <v-flex xs12 id="alur"><v-img :src="'https://drive.google.com/thumbnail?id=1UnkJyF_xv5QUYb2MspGURcET-xCf37eW&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex> -->
        <v-flex xs12 id="alur">
           <v-card-title class="deep-purple darken-2">
              <span class="headline white--text">ALUR MENJADI MITRA KAMI</span>
          </v-card-title>
                  <v-stepper
                    v-model="e13"
                    vertical
                  >
                    <v-stepper-step
                      step="1"
                      complete
                    >
                      MEMILIH PAKET
                    </v-stepper-step>

                    <v-stepper-content step="1">
                      <v-card
                        color="blue lighten-5"
                        class="mb-12"
                        height="200px"
                      >
                      <p class="text-justify pa-2">Anda bisa memilih paket sesuai kebutuhan dan budget anda</p>
                      </v-card>
                      <v-btn
                        color="primary"
                        @click="e13 = 2"
                      >
                        Selanjutnya
                      </v-btn>
                      <v-btn text>
                        Sebelumnya
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                      step="2"
                      complete
                    >
                      MENGHUBUNGI TIM MARKETING
                    </v-stepper-step>

                    <v-stepper-content step="2">
                      <v-card
                        color="blue lighten-5"
                        class="mb-12"
                        height="200px"
                      >
                      <p class="text-justify pa-2">Menghubungi TIM marketing kami untuk menanyakan informasi Bisnisnya</p>
                      </v-card>
                      <v-btn
                        color="primary"
                        @click="e13 = 3"
                      >
                        Selanjutnya
                      </v-btn>
                      <v-btn 
                        text
                        @click="e13 = 1">
                        Sebelumnya
                      </v-btn>
                    </v-stepper-content>
                    
                    <v-stepper-step
                      step="3"
                      complete
                    >
                      MEMBAYAR DP 50%
                    </v-stepper-step>

                    <v-stepper-content step="3">
                      <v-card
                        color="blue lighten-5"
                        class="mb-12"
                        height="200px"
                      >
                      <p class="text-justify pa-2">Membayar DP Sebesar 50% dari harga paket sebagai tanda jadi (Harga sesuai dengan paket yang dipilih)</p>
                      </v-card>
                      <v-btn
                        color="primary"
                        @click="e13 = 4"
                      >
                        Selanjutnya
                      </v-btn>
                      <v-btn 
                        text
                        @click="e13 = 2">
                        Sebelumnya
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                      step="4"
                      complete
                    >
                      TANDA TANGAN MOU
                    </v-stepper-step>

                    <v-stepper-content step="4">
                      <v-card
                        color="blue lighten-5"
                        class="mb-12"
                        height="200px"
                      >
                      <p class="text-justify pa-2">Surat perjanjian akan dikirimkan ke alamat Mitra melalui Kantor POS</p>
                      </v-card>
                      <v-btn
                        color="primary"
                        @click="e13 = 5"
                      >
                        Selanjutnya
                      </v-btn>
                      <v-btn 
                       text
                        @click="e13 = 3">
                        Sebelumnya
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                      step="5"
                      complete
                    >
                      PELUNASAN PAKET
                    </v-stepper-step>

                    <v-stepper-content step="5">
                      <v-card
                        color="blue lighten-5"
                        class="mb-12"
                        height="200px"
                      >
                      <p class="text-justify pa-2">Mitra bisa melunasi sisa pembayaran untuk paketnya</p>
                      </v-card>
                      <v-btn
                        color="primary"
                        @click="e13 = 6"
                      >
                        Selanjutnya
                      </v-btn>
                      <v-btn 
                       text
                        @click="e13 = 4">
                        Sebelumnya
                      </v-btn>
                    </v-stepper-content>
                    <v-stepper-step
                      step="6"
                      complete
                    >
                      PENGIRIMAN PAKET USAHA
                    </v-stepper-step>

                    <v-stepper-content step="6">
                      <v-card
                        color="blue lighten-5"
                        class="mb-12"
                        height="200px"
                      >
                      <p class="text-justify pa-2">Paket usaha siap dikirim dan Mitra dapat langsung Grand Opening</p>
                      </v-card>
                      <v-btn
                        color="primary"
                        @click="e13 = 6"
                      >
                        SELESAI
                      </v-btn>
                      <v-btn 
                       text
                        @click="e13 = 5">
                        Sebelumnya
                      </v-btn>
                    </v-stepper-content>

                    
                  </v-stepper>
        </v-flex>
        <v-flex xs12 id=""><v-img :src="'https://drive.google.com/thumbnail?id=1fJhLbDFjQDAxx4_6SuNqGX7B2bXmzi71&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex>
        <!--<v-flex xs12 id=""><a href="https://shopee.co.id/jokercorpofficial?smtt=0.0.9"></a><v-img :src="'https://drive.google.com/thumbnail?id=19eXet-Oi56f_orYbm_KwD-GILKzMEsE_&sz=w' + width + '-rw'" alt="Responsive image"></v-img></a></v-flex>-->
        <!--<v-flex xs12 id=""><a href="https://www.tokopedia.com/jokermarketplace"><v-img :src="'https://drive.google.com/thumbnail?id=1dsSC4twCfZyKAfymerB4MqwKC_pNSP3G&sz=w' + width + '-rw'" alt="Responsive image"></v-img></a></v-flex>-->
        <v-flex xs12 class="pa-15">
            <v-btn  href="whatsapp.php" block icon-and-text dark color="green darken-4">
                <v-icon left>mdi-whatsapp</v-icon>
                    CHAT ADMIN
            </v-btn>
        </v-flex>
        

    </v-layout>
    <v-layout row wrap>
        <v-flex xs12 class="mb-10">
         <v-footer padless >
            <v-col
              class="text-center"
              cols="12"
            >
              {{ new Date().getFullYear() }} — <strong>Joker Corporation</strong>
            </v-col>
        </v-footer>
        </v-flex>
     </v-layout>
     <div  class="px-10">
      <v-bottom-navigation
              dark
              fixed
              horizontal
              background-color="green darken-3"
              
            >
              <v-btn 
                href="validasi.php?"
                color="deep-purple accent-4"
                text
              >
                <span>Chat Admin</span>
        
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </v-bottom-navigation>
            </div>
      </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
          drawer: false,
          img_url: 'https://drive.google.com/thumbnail?id=',
          loading: true,
          width: 0,
          selectedItem: 1,
          e13:1,
          
          timeline: [
                  {
                    color: 'red lighten-2',
                    icon: 'mdi-star',
                  },
                  {
                    color: 'purple darken-1',
                    icon: 'mdi-book-variant',
                  },
                  {
                    color: 'green lighten-1',
                    icon: 'mdi-airballoon',
                  },
                  {
                    color: 'indigo',
                    icon: 'mdi-buffer',
                  },
                ],
          alasan: [
                   { text: 'Harga Paket Terjangkau', id: '#beranda' },
                   { text: '100% Keuntungan Milik Mitra', id: '#beranda' },
                   { text: 'beranda', id: '#beranda' },
                   { text: 'beranda', id: '#beranda' },
                ],
          // items1: [
          //   { image: '1w3KE_TKcvjBR4ZfIewi6_kOLdZHN89LA', id: 'none'},
          //   { image: '1Eu3lk0lIOKSAqGUYbsLgKFwxfeQBMnia', id: 'none'},
          //   { image: '1X7LsttrIPIdZLc3vwEAeQElHAIIs5lA5', id: 'none'},
          //   { image: '1G979ELHENxqToxOjNbb4rstkz25J945y', id: 'none'},
          //   { image: '1S2SrJ78YhG1RJqezqdD0pXKoPmDy9kAZ', id: 'none'},
          //   { image: '1UH7aaSkg4EhApTzyVIh78NxIKc9Dvchc', id: 'none'},
          //   { image: '1VulLqOUdbx7jzg90v8kcJ9An57MwT10e', id: 'none'},
          // ],
          items2: [
            { text: 'beranda', id: '#beranda' },
            { text: 'promo', id: '#promo' },
            { text: 'Testimoni', id: '#testi' },
            { text: 'Paket', id: '#paket' },
            { text: 'Produk', id: '#produk' },
            { text: 'Kemitraan', id: '#alur' },
            { text: 'Balik Modal', id: '#roi' },
            { text: 'Alamat', id: '#alamat' },
          ],

      
      // items3: [
      //   { text: 'Sudah berdiri lebih dari 10 Tahun', icon: 'mdi-checkbox-marked-circle' },
      //   { text: 'Lebih dari Dua ribu Mitra kami yang sudah berhasil mendapatkan tambahan penghasilan.', icon: 'mdi-checkbox-marked-circle' },
      //   { text: 'Support outlite dangan Iklan', icon: 'mdi-checkbox-marked-circle' },
      //   { text: 'Support RnD untuk training pembuatan Leker anti gagal.', icon: 'mdi-checkbox-marked-circle' },
      //   { text: 'Support outlite dangan Iklan', icon: 'mdi-checkbox-marked-circle' },
      // ],
        }),
        methods: {
            ukuran() {
                this.width = window.innerWidth + 200
            },
            skeleton() {
              setTimeout(() => (this.loading = false), 700)  
            },
        },
        mounted() {
          this.skeleton();
          this.ukuran();  
        }
};
</script>
